<template>
  <div class="p-1">
    <div v-if="showError" class="w-100 text-center pt-4">
      <h2 class="mb-1">Page Not Found 🕵🏻‍♀️</h2>
      <p class="mb-2">
        Oops! 😖 The requested URL was not found on this server.
      </p>
      <b-img fluid :src="imgUrl" alt="Error page" />
    </div>
    <template v-else>
      <b-modal
        id="modal-login"
        hide-footer
        hide-header
        centered
        no-close-on-backdrop
        title=""
        size="sm"
      >
        <b-row align-h="center">
          <b-spinner
            class="m-5"
            style="width: 4rem; height: 4rem"
            variant="primary"
            label="Spinning"
          ></b-spinner>
        </b-row>
      </b-modal>

      <b-row align-h="center" class="mb-1">
        <b-col class="text-center" lg="7" md="9">
          <b-img
            v-if="school.logo == null || school.logo == ''"
            ref="previewEl"
            src="https://cdn.cloudious.net/file-1678110140675-811622963.png"
            style="object-fit: contain"
            fluid
            height="100px"
            width="100px"
          />
          <b-img
            v-else
            ref="previewEl"
            :src="school.logo"
            style="object-fit: contain"
            fluid
            height="100px"
            width="100px"
          />
          <div class="mt-1">
            <h3>{{ school.name }}</h3>
            <h5>
              <feather-icon icon="MapPinIcon" class="mr-50" />
              {{ school.address }}
            </h5>
            <h5>
              <feather-icon icon="PhoneIcon" class="mr-50" />
              {{ school.phone || school.mobile }}
            </h5>
          </div>
        </b-col>
        <b-col v-if="submitted" class="text-center" lg="7" md="9">
          <div style="height: 280px">
            <lottie-animation path="submitted.json" />
          </div>
          <b-button variant="primary" @click="cleanStates()">
            <span>Add More</span>
          </b-button>
        </b-col>
      </b-row>
      <b-row align-h="center" v-if="!showForm && !submitted">
        <b-col md="6">
          <b-card>
            <h4 class="text-uppercase mb-0">Student Registration</h4>
            <hr />
            <div v-if="otpLoading" class="text-center">
              <b-spinner
                class="m-1"
                style="width: 2.5rem; height: 2.5rem"
                variant="primary"
                label="Spinning"
              ></b-spinner>
            </div>
            <b-row v-else-if="showOtp">
              <b-col md="12">
                <h5 class="mb-1">
                  We sent a verification code to your contact ******{{
                    otpContact.slice(-3)
                  }}. Enter the code in the field below.
                </h5>
              </b-col>
              <b-col md="12" class="d-flex">
                <b-form-input
                  v-for="(item, ind) in enteredCode"
                  :key="ind"
                  type="number"
                  max="9"
                  min="0"
                  :no-wheel="true"
                  :ref="'code' + ind"
                  v-model="enteredCode[ind]"
                  class="text-center mx-50"
                  @input="moveNext(ind)"
                />
              </b-col>
              <b-col md="12" class="mt-1 text-center">
                <b-button
                  variant="primary"
                  :disabled="verifyToggle"
                  @click="verifyOtp()"
                >
                  Verify
                </b-button>
              </b-col>
            </b-row>
            <b-row v-else>
              <b-col md="12">
                <h5 class="mb-1">
                  Please enter your contact number to proceed the registration.
                </h5>
              </b-col>
              <b-col xl="9" sm="8">
                <b-form-group
                  label="Contact No."
                  invalid-feedback="Contact No. is required."
                  class="mb-0"
                >
                  <vue-tel-input
                    v-model="otpContact"
                    :inputOptions="vueTelOptions"
                    :dropdownOptions="dropOptions"
                    @input="CheckOTPContact"
                  ></vue-tel-input>
                  <small class="mr-1 text-danger" v-if="otpError"
                    >Contact number is required.</small
                  >
                </b-form-group>
              </b-col>
              <b-col xl="3" sm="4">
                <b-button
                  variant="primary"
                  block
                  class="btn-icon mt-2"
                  @click="checkStudent()"
                >
                  <!-- @click="sendOtp()" -->
                  Send
                </b-button>
              </b-col>
            </b-row>
          </b-card>
          <b-card v-if="false">
            <div v-if="inqLoading" class="text-center">
              <b-spinner
                class="m-1"
                style="width: 2.5rem; height: 2.5rem"
                variant="primary"
                label="Spinning"
              ></b-spinner>
            </div>
            <b-row v-else>
              <b-col xl="9" sm="8">
                <b-form-group
                  label="Inquiry No."
                  invalid-feedback="Inquiry No. is required."
                  ref="inq"
                  class="mb-0"
                >
                  <b-form-input
                    type="number"
                    placeholder="Enter inquiry no."
                    v-model="inqNo"
                    @keyup.enter="getInq()"
                  />
                </b-form-group>
              </b-col>
              <b-col xl="3" sm="4">
                <b-button
                  variant="primary"
                  block
                  class="btn-icon mt-2"
                  @click="getInq()"
                >
                  Search
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>

      <template v-else-if="showForm && !submitted">
        <form-wizard
          v-if="false"
          ref="wizard"
          color="#7367F0"
          :title="null"
          :subtitle="null"
          shape="square"
          finish-button-text="Submit"
          back-button-text="Previous"
          class="steps-transparent mb-1"
          @on-complete="shortcutSubmit"
        >
          <!-- account detail tab   :before-change="validationForm"-->
          <tab-content title="Student" icon="feather icon-file-text">
            <!-- :before-change="validationForm" -->
          </tab-content>
          <!-- personal details -->
          <tab-content
            title="Family"
            icon="feather icon-users"
            :before-change="validationFamilyInfo"
          >
            <b-row v-if="studentID > 0" class="mb-1">
              <b-col>
                <b-media vertical-align="center">
                  <template #aside>
                    <b-avatar
                      size="44"
                      :src="myObj.picture"
                      variant="light-primary"
                    />
                  </template>

                  <b-link
                    class="font-weight-bold d-block text-nowrap"
                    style="margin-bottom: 4px"
                  >
                    <b-badge variant="light-primary">
                      {{ myObj.name }}
                    </b-badge>
                  </b-link>
                  <small class="text-muted">
                    <b-badge variant="light-primary">
                      GR - {{ myObj.grNo }}
                    </b-badge>
                    <b-badge variant="light-primary" class="ml-50">
                      F# - {{ myObj.family_code }}
                    </b-badge>
                  </small>
                </b-media>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="6">
                <b-card class="">
                  <h3 class="mt-1">Tutor Information</h3>
                  <hr />
                  <b-row>
                    <b-col md="12">
                      <b-form-group
                        label="Contact"
                        invalid-feedback="School name is required."
                      >
                        <b-form-input
                          v-model="myObj.contact_tutor"
                          placeholder="Enter tutor contact no"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
          </tab-content>

          <tab-content title="Documents" icon="feather icon-link">
            <b-row align-h="center">
              <b-col md="8">
                <b-row v-if="studentID > 0" class="mb-1">
                  <b-col>
                    <b-media vertical-align="center">
                      <template #aside>
                        <b-avatar
                          size="44"
                          :src="myObj.picture"
                          variant="light-primary"
                        />
                      </template>

                      <b-link
                        class="font-weight-bold d-block text-nowrap"
                        style="margin-bottom: 4px"
                      >
                        <b-badge variant="light-primary">
                          {{ myObj.name }}
                        </b-badge>
                      </b-link>
                      <small class="text-muted">
                        <b-badge variant="light-primary">
                          GR - {{ myObj.grNo }}
                        </b-badge>
                        <b-badge variant="light-primary" class="ml-50">
                          F# - {{ myObj.family_code }}
                        </b-badge>
                      </small>
                    </b-media>
                  </b-col>
                </b-row>
                <div class="file-uploader">
                  <h5>
                    <b-spinner
                      variant="dark"
                      label="Spinning"
                      v-if="uploading"
                    ></b-spinner>
                    <span v-else> Upload Documents </span>
                  </h5>
                  <!-- {{ getFileNames === "" ? "Upload your document here" : getFileNames }} -->
                  <input
                    ref="studentdocs"
                    type="file"
                    class="file_upload"
                    @change="uploadDocument()"
                    multiple="true"
                  />
                </div>
              </b-col>
            </b-row>

            <b-row align-h="center">
              <b-col md="8">
                <!-- <template v-for="(file, index) in documents">
                      <div class="file">
                        <div style="display: flex; align-items: center">
                          <div class="file-type">
                            {{ file.name.split(".").pop().toUpperCase() }}
                          </div>
                          <p class="file-name">{{ file.name }}</p>
                        </div>
                        <div class="file-delete">
                          <i
                            class="fa fa-trash"
                            aria-hidden="true"
                            @click="deleteDocument(index)"
                          ></i>
                        </div>
                      </div>
                    </template> -->
                <template v-for="(file, index) in documents">
                  <div class="file">
                    <div style="display: flex; align-items: center">
                      <div class="file-type">
                        {{ file.split(".").pop().toUpperCase() }}
                      </div>
                      <b-link :href="file" target="_blank">
                        {{ file }}
                      </b-link>
                      <!-- <a class="file-name">{{ file }}</a> -->
                    </div>
                    <div class="file-delete">
                      <i
                        class="fa fa-trash"
                        aria-hidden="true"
                        @click="deleteDocument(index)"
                      ></i>
                    </div>
                  </div>
                </template>
              </b-col>
            </b-row>
            <!-- <b-col lg="3" xl="3" md="2" sm="1"> </b-col> -->
            <!-- <b-col lg="6" xl="6" md="8" sm="10">
                    <b-card class="p-1 card">
                      <h3 class="mt-1">Add Docs</h3>
                      <hr />
                      <b-row class="text-center">
                        <b-col md="12">
                          <b-link class="p-1">
                            <b-img
                              ref="previewEl"
                              rounded
                              src="https://dk0pm9zdlq16s.cloudfront.net/8cbeb020-efb6-4a78-993c-871a94ddbbac.png"
                              @click="$refs.mfile.click()"
                              v-if="moreloading == false"
                              height="140"
                              width="140"
                              thumbnail
                            />
                          </b-link>
        
                          <input
                            type="file"
                            id="mfile"
                            hidden
                            ref="mfile"
                            multiple="multiple"
                            @change="selectMoreFile()"
                            accept="*"
                          />
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col cols="12">
                          <div>
                            <b-badge
                              style="margin-bottom: 5px; font-size: 15px"
                              class="mt-1"
                              v-for="(file, index) in mydocs"
                              :key="index"
                              variant="light-primary"
                            >
                              <i
                                v-if="file.orginal.split('.')[1] == 'pdf'"
                                class="fas fa-file-pdf mb-1"
                                style="font-size: 1.5em; float: left"
                              ></i>
                              <i
                                v-if="file.orginal.split('.')[1] == 'jpg'"
                                class="fas fa-file-image mb-1"
                                style="font-size: 1.5em; float: left"
                              ></i>
                              <i
                                v-if="file.orginal.split('.')[1] == 'png'"
                                class="far fa-file-image mb-1"
                                style="font-size: 1.5em; float: left"
                              ></i>
        
                              <div
                                style="font-size: 14px; margin-top: 5px; float: left"
                              >
                                {{ " : " + file.path.slice(0, 36) }}
                              </div>
        
                              <i
                                style="
                                  font-size: 1em;
                                  margin-inline: 5px;
                                  margin-top: 5px;
                                  float: left;
                                "
                                @click="deleteDocument(index)"
                                class="text-danger fa fa-trash"
                              ></i>
                            </b-badge>
                          </div>
                        </b-col>
                      </b-row>
                    </b-card>
                  </b-col> -->
            <!-- <div style="height: 500px; width: 500px; border: 1px solid red; position: relative;">
          <DropZone 
              :maxFiles="Number(10000000000)"
              url="http://localhost:5000/item"
              :uploadOnDrop="true"
              :multipleUpload="true"
              :parallelUpload="3"/>
        </div> -->
            <!-- <b-col lg="3" xl="3" md="2" sm="1"> </b-col> -->
          </tab-content>

          <!-- next & prev buttons -->
          <template slot="footer" slot-scope="props">
            <div class="wizard-footer-left d-none"></div>
            <div class="wizard-footer-right d-none"></div>
          </template>
        </form-wizard>

        <b-row align-h="center" class="match-height">
          <b-col lg="7" md="9">
            <b-card class="" body-class="position-relative">
              <h3 class="">Student Information</h3>
              <hr />
              <b-row align-h="center">
                <b-col class="ml-3" cols="4">
                  <b-form-group
                    class="text-center"
                    ref="picture"
                    label=""
                    invalid-feedback="Picture is required."
                  >
                    <b-media no-body>
                      <div v-if="logoloading == true">
                        <b-spinner
                          variant="primary"
                          type="grow"
                          class="m-5"
                          label="Spinning"
                        ></b-spinner>
                      </div>

                      <b-media-aside>
                        <b-link>
                          <b-img
                            src="https://cdn.cloudious.net/file-1677912450367-333289208.png"
                            @click="$refs.fileProfile.click()"
                            v-if="logoloading == false"
                            style="border-radius: 100px"
                            fluid
                            height="120px"
                            width="120px"
                          />
                        </b-link>

                        <b-link v-if="logoloading == 'loaded'">
                          <div class="text-right" style="margin-right: 35px">
                            <b-button
                              @click="deleteLogo()"
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              class="position-absolute btn-icon"
                              variant="danger"
                            >
                              <feather-icon icon="Trash2Icon" />
                            </b-button>
                          </div>

                          <b-img
                            class="rcorners2"
                            :src="plus"
                            height="120px"
                            width="120px"
                          />

                          <br />
                        </b-link>
                        <input
                          type="file"
                          id="fileProfile"
                          hidden
                          ref="fileProfile"
                          @change="selectlogo()"
                          accept="image/*"
                        />
                      </b-media-aside>
                    </b-media>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="12">
                  <b-form-group
                    label="Applying for Campus *"
                    invalid-feedback="Campus is required."
                    ref="camp"
                    label-class="font-weight-bold"
                  >
                    <v-select
                      v-model="myObj.campusID"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="campuses"
                      disabled
                      :clearable="false"
                      :reduce="(val) => val.id"
                      label="branch"
                      placeholder="Select campus"
                      @input="setData()"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Name *"
                    invalid-feedback="Student name is required."
                    label-class="font-weight-bold"
                  >
                    <b-form-input
                      placeholder="Enter name."
                      ref="name"
                      v-model.trim="myObj.name"
                      @focusout="CheckName()"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group
                    label="Father name *"
                    invalid-feedback="Father name is required."
                    label-class="font-weight-bold"
                  >
                    <b-form-input
                      ref="fname"
                      placeholder="Enter father name."
                      v-model.trim="myObj.father_name"
                      @focusout="CheckFName()"
                    />
                  </b-form-group>
                </b-col>

                <!-- <b-col md="6">
                          <b-form-group
                            label="Surname"
                            invalid-feedback="Surname is required."
                          >
                            <b-form-input
                              placeholder="Enter Surname"
                              v-model="myObj.surname"
                            />
                          </b-form-group>
                        </b-col>
      
                        <b-col md="6">
                          <b-form-group
                            label="Religion"
                            invalid-feedback="School name is required."
                          >
                            <v-select
                              v-model="myObj.religion"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :options="religions"
                              :clearable="false"
                              input-id="religionId"
                              ref="religion"
                              :disabled="dataloading"
                              placeholder="Select religion"
                            />
                          </b-form-group>
                        </b-col> -->
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    label="Class *"
                    invalid-feedback="Class is required."
                    ref="class_admitted"
                    label-class="font-weight-bold"
                  >
                    <v-select
                      v-model="myObj.class_admitted"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="classesOptions"
                      :clearable="false"
                      :reduce="(val) => val.id"
                      input-id="id"
                      label="name"
                      ref="class_admitted"
                      @search:blur="CheckClassAdmit()"
                      :disabled="dataloading"
                      placeholder="Select class"
                    />
                    <!-- @input="FillSections()" -->
                  </b-form-group>
                </b-col>
                <b-col md="6" v-if="false">
                  <b-form-group
                    label="Section *"
                    invalid-feedback="Section is required."
                    ref="section_admitted"
                  >
                    <v-select
                      v-model="myObj.section_admitted"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="admittedSections"
                      :reduce="(val) => val.id"
                      label="section"
                      :clearable="false"
                      :disabled="dataloading"
                      placeholder="Select section"
                      @input="CheckAdmitSec()"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Gender *"
                    invalid-feedback="Gender is required."
                    label-class="font-weight-bold"
                    ref="gender"
                  >
                    <v-select
                      v-model="myObj.gender"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="genders"
                      :clearable="false"
                      placeholder="Select gender"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Date of Birth *"
                    invalid-feedback="Date of Birth is required."
                    label-class="font-weight-bold"
                    ref="dob"
                  >
                    <flat-pickr
                      :config="config"
                      v-model="myObj.date_of_birth"
                      class="form-control"
                      placeholder="Select date of birth."
                      @on-change="formatDate()"
                    />
                    <!-- @on-change="CheckDob()" -->
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <!-- <b-col md="6">
                          <b-form-group
                            label="Contact"
                            invalid-feedback="Contact number is required."
                          >
                            <b-form-input
                              placeholder="Enter contact number."
                              v-model="myObj.contact_student"
                            />
                          </b-form-group>
                        </b-col> -->

                <b-col md="8">
                  <b-form-group
                    label="Last Institute"
                    invalid-feedback="Last Institute is required."
                  >
                    <b-form-input
                      placeholder="Enter last institute."
                      v-model="myObj.last_institute"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group
                    label="Last Year Attended"
                    invalid-feedback="Last Year Attended is required."
                  >
                    <b-form-input
                      placeholder="Enter last year."
                      v-model="myObj.last_session"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label="Place of birth"
                    invalid-feedback="Place of birth is required."
                  >
                    <b-form-input
                      placeholder="Enter place of birth"
                      v-model="myObj.place_of_birth"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Mother Tongue"
                    invalid-feedback="Mother Tongue is required."
                  >
                    <b-form-input
                      placeholder="Enter mother tongue"
                      v-model="myObj.mother_tongue"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Nationality"
                    invalid-feedback="Nationality is required."
                  >
                    <b-form-input
                      placeholder="Enter nationality"
                      v-model="myObj.nationality"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <!-- <b-row>
                        <b-col md="12">
                          <b-form-group
                            label="Date of birth in words."
                            invalid-feedback="Contact number is required."
                          >
                            <b-form-input
                              placeholder="Enter date of birth in words."
                              v-model="myObj.date_of_birth_words"
                            />
                          </b-form-group>
                        </b-col>
                      </b-row> -->
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label="Current Address"
                    invalid-feedback="Contact number is required."
                  >
                    <b-form-input
                      placeholder="Enter current address."
                      v-model="myObj.address_current"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <!-- <b-row>
                        <b-col md="12">
                          <b-form-group
                            label="Permanent Address"
                            invalid-feedback="Contact number is required."
                          >
                            <b-form-input
                              placeholder="Enter permanent address."
                              v-model="myObj.address_permanent"
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col md="12">
                          <b-form-group
                            label="Physical Handicap"
                            invalid-feedback="Contact number is required."
                          >
                            <b-form-input
                              placeholder="Enter physical handicap."
                              v-model="myObj.physical_handicap"
                            />
                          </b-form-group>
                        </b-col>
                      </b-row> -->
              <!-- <b-row> 
                    <b-col md="6">
                      <b-form-group
                        style="position: relative"
                        label="Family code"
                        invalid-feedback="Family code is required."
                      >
                        <b-form-input
                          type="number"
                          v-model="myObj.family_code"
                          class="form-control"
                          placeholder="Enter family code"
                          disabled
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        label="Reg number *"
                        invalid-feedback="Reg number is required"
                        ref="grNo"
                      >
                        <b-form-input
                          v-model="myObj.grNo"
                          class="form-control"
                          placeholder="Enter reg number"
                          @focusout="CheckGrNo()"
                          disabled
                        />
                      </b-form-group>
                    </b-col>
                  </b-row> -->
            </b-card>

            <b-card class="">
              <h3 class="">Father Information</h3>
              <hr />
              <b-row>
                <b-col md="6">
                  <b-form-group
                    label="Occupation"
                    invalid-feedback="School name is required."
                  >
                    <b-form-input
                      v-model="myObj.father_occupation"
                      placeholder="Enter Occupation"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="CNIC no "
                    invalid-feedback="CNIC is required."
                  >
                    <b-form-input
                      ref="fCnic"
                      v-model="myObj.father_cnic"
                      placeholder="Enter CNIC number"
                    />
                    <!-- @focusout="CheckCnic()" -->
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    label="Education"
                    invalid-feedback="School name is required."
                  >
                    <b-form-input
                      v-model="myObj.father_education"
                      placeholder="Enter Education"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Monthly income">
                    <b-form-input
                      type="number"
                      v-model="myObj.monthly_income"
                      placeholder="Enter monthly income"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    label="Email"
                    invalid-feedback="Email is required."
                  >
                    <b-form-input
                      ref="fEmail"
                      v-model="myObj.father_email"
                      placeholder="Enter Email"
                    />
                  </b-form-group>
                  <!-- @focusout="CheckEmail()" -->
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Employer (Firm)"
                    invalid-feedback="Contact number is required."
                  >
                    <b-form-input
                      v-model="myObj.father_employer"
                      placeholder="Enter Employer"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    label="Office Contact"
                    invalid-feedback="Contact number is required."
                  >
                    <b-form-input
                      v-model="myObj.office_phone"
                      placeholder="Enter office number"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Office Address"
                    invalid-feedback="Contact number is required."
                  >
                    <b-form-input
                      placeholder="Enter office address"
                      v-model="myObj.office_address"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>

            <b-card class="">
              <h3 class="">Mother Information</h3>
              <hr />
              <b-row>
                <b-col md="6">
                  <b-form-group
                    label="Name"
                    invalid-feedback="School name is required."
                  >
                    <b-form-input
                      v-model="myObj.mother_name"
                      placeholder="Enter name"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group
                    label="Occupation"
                    invalid-feedback="School name is required."
                  >
                    <b-form-input
                      v-model="myObj.mother_occupation"
                      placeholder="Enter occupation"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    label="CNIC no"
                    invalid-feedback="CNIC is required."
                  >
                    <b-form-input
                      ref="mCnic"
                      v-model="myObj.mother_cnic"
                      placeholder="Enter CNIC number"
                    />
                    <!-- @focusout="CheckMCnic()" -->
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Education"
                    invalid-feedback="School name is required."
                  >
                    <b-form-input
                      v-model="myObj.mother_education"
                      placeholder="Enter education."
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Monthly income">
                    <b-form-input
                      type="number"
                      v-model="myObj.mother_income"
                      placeholder="Enter monthly income"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Email"
                    invalid-feedback="School name is required."
                  >
                    <b-form-input
                      v-model="myObj.mother_email"
                      placeholder="Enter email address"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Employer"
                    invalid-feedback="School name is required."
                  >
                    <b-form-input
                      v-model="myObj.mother_employer"
                      placeholder="Enter employer"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Office Contact"
                    invalid-feedback="School name is required."
                  >
                    <b-form-input
                      v-model="myObj.mother_office_phone"
                      placeholder="Enter office contact"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Office Address"
                    invalid-feedback="School name is required."
                  >
                    <b-form-input
                      v-model="myObj.mother_office_address"
                      placeholder="Enter office address"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>

            <b-card class="">
              <h3 class="">Guardian Information</h3>
              <hr />
              <b-row>
                <b-col md="6">
                  <b-form-group
                    label="Name"
                    invalid-feedback="School name is required."
                  >
                    <b-form-input
                      placeholder="Enter guardian name."
                      v-model="myObj.guardian_name"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Relation"
                    invalid-feedback="School name is required."
                  >
                    <b-form-input
                      placeholder="Enter guardian relation."
                      v-model="myObj.guardian_relationship"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="6">
                  <b-form-group
                    label="Occupation"
                    invalid-feedback="School name is required."
                  >
                    <b-form-input
                      placeholder="Enter guardian occupation."
                      v-model="myObj.guardian_occupation"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="CNIC no"
                    invalid-feedback="Contact number is required."
                  >
                    <b-form-input
                      ref="gCnic"
                      placeholder="Enter CNIC number."
                      v-model="myObj.guardian_cnic"
                    />
                    <!-- @focusout="CheckGCnic()" -->
                  </b-form-group>
                </b-col>
                <!-- <b-col md="6">
                          <b-form-group
                            label="Mobile No"
                            invalid-feedback="School name is required."
                          >
                            <b-form-input placeholder="Enter mobile number" />
                          </b-form-group>
                        </b-col> -->
                <b-col md="6"> </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    label="Email"
                    invalid-feedback="Contact number is required."
                  >
                    <b-form-input
                      ref="gEmail"
                      placeholder="Enter email addresss."
                      v-model="myObj.guardian_email"
                    />
                    <!-- @focusout="CheckGEMail()" -->
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Employer"
                    invalid-feedback="Contact number is required."
                  >
                    <b-form-input
                      placeholder="Enter employer."
                      v-model="myObj.guardian_employer"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    label="Office Contact"
                    invalid-feedback="Contact number is required."
                  >
                    <b-form-input
                      placeholder="Enter office number"
                      v-model="myObj.guardian_office_phone"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group
                    label="Education"
                    invalid-feedback="Contact number is required."
                  >
                    <b-form-input
                      placeholder="Enter Education"
                      v-model="myObj.guardian_education"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label="Office Address"
                    invalid-feedback="Contact number is required."
                  >
                    <b-form-input
                      placeholder="Enter office contact number"
                      v-model="myObj.guardian_office_address"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>

            <b-card class="">
              <h3 class="">Contact Information</h3>
              <hr />
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label="Father's Contact *"
                    invalid-feedback="Please enter correct contact number."
                    label-class="font-weight-bold"
                  >
                    <vue-tel-input
                      v-model="myObj.contact_father"
                      :inputOptions="vueTelOptions"
                      :dropdownOptions="dropOptions"
                      @input="CheckFContact"
                    ></vue-tel-input>
                    <small class="mr-1 text-danger" v-if="fphoneerror"
                      >Phone number is required.</small
                    >
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label="Mother's Contact"
                    invalid-feedback="Please enter correct phone number."
                  >
                    <vue-tel-input
                      :inputOptions="vueTelOptions"
                      @input="CheckMContact"
                      :dropdownOptions="dropOptions"
                      v-model="myObj.contact_mother"
                    ></vue-tel-input>
                    <small class="mr-1 text-danger" v-if="mphoneerror"
                      >Phone number is required.</small
                    >
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label="Student's Contact"
                    invalid-feedback="Please enter correct phone number."
                  >
                    <vue-tel-input
                      :inputOptions="vueTelOptions"
                      :dropdownOptions="dropOptions"
                      @input="CheckContact"
                      v-model="myObj.contact_student"
                    ></vue-tel-input>
                    <small class="mr-1 text-danger" v-if="phoneerror"
                      >Phone number is required.</small
                    >
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>

            <b-card class="">
              <h3 class="">Emergency Information</h3>
              <hr />
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label="Name"
                    invalid-feedback="Name is required."
                  >
                    <b-form-input
                      ref="emerName"
                      placeholder="Enter name."
                      v-model="myObj.emergency_name"
                    />
                    <!-- @focusout="CheckEmerName()" -->
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label="Relation"
                    invalid-feedback="Relation is required."
                  >
                    <b-form-input
                      ref="emerRel"
                      placeholder="Enter relation."
                      v-model="myObj.emergency_relationship"
                    />
                    <!-- @focusout="CheckEmerRelation()" -->
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label="Emergency contact"
                    invalid-feedback="Emergency Contact is required."
                  >
                    <vue-tel-input
                      v-model="myObj.emergency_contact"
                      :inputOptions="emerOpt"
                      :dropdownOptions="dropOptions"
                      @input="CheckEmerContact"
                    ></vue-tel-input>
                    <small class="mr-1 text-danger" v-if="emerPhoneerror"
                      >Phone number is required.</small
                    >
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>

            <b-card class="">
              <h3 class="">Other Information</h3>
              <hr />
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label="Allergies"
                    invalid-feedback="Allergies is required."
                  >
                    <b-form-input
                      placeholder="Enter allergies."
                      v-model="myObj.allergies"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="12">
                  <b-form-group
                    label="Note"
                    invalid-feedback="Note is required."
                  >
                    <b-form-textarea
                      id="textarea-default"
                      placeholder="Enter note"
                      rows="3"
                      v-model="myObj.remarks"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>

            <b-card class="">
              <h3 class="">Sibling Information</h3>
              <hr />
              <b-row>
                <b-col md="12" v-if="siblings.length == 0">
                  <h3 class="text-center">No records found</h3>
                </b-col>
                <b-col
                  md="12"
                  v-for="(item, ind) in siblings"
                  :key="ind"
                  class="mb-1"
                >
                  <b-media vertical-align="center">
                    <template #aside>
                      <b-avatar
                        size="44"
                        :src="item.picture"
                        variant="light-primary"
                      />
                    </template>

                    <b-link class="font-weight-bold d-block text-nowrap mb-25">
                      <b-badge variant="light-primary" class="mr-50">
                        {{ item.name }} - ({{ item.status }})
                      </b-badge>
                    </b-link>
                    <b-link class="font-weight-bold d-block text-nowrap mb-25">
                      <b-badge variant="light-primary" class="mr-50">
                        GR# {{ item.grNo }}
                      </b-badge>
                      <b-badge variant="light-primary">
                        ID: {{ item.id }}
                      </b-badge>
                    </b-link>
                    <b-link class="font-weight-bold d-block text-nowrap">
                      <b-badge variant="light-primary" class="mr-50">
                        {{ item.campus }}
                      </b-badge>
                      <b-badge variant="light-primary">
                        {{ item.cls }} {{ item.section }}
                      </b-badge>
                    </b-link>
                  </b-media>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
        <b-row align-h="center">
          <b-col lg="7" md="9">
            <b-button block variant="success" @click="shortcutSubmit()">
              {{
                myObj.status.toLowerCase() == "registered" &&
                (this.$route.params.readmit == true ||
                  this.$route.params.readmit == "true")
                  ? "Confirm Admission"
                  : "Save"
              }}
            </b-button>
          </b-col>
        </b-row>
      </template>
    </template>
  </div>
</template>

<script>
import { codeIcon } from "./code";
import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import Ripple from "vue-ripple-directive";
import { mapActions, mapGetters } from "vuex";
// import DropZone from 'dropzone-vue';
import vue2Dropzone from "vue2-dropzone";

import "vue2-dropzone/dist/vue2Dropzone.min.css";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";

// // optionally import default styles
// import 'dropzone-vue/dist/dropzone-vue.common.css';

// createApp(App)
//   .use(DropZone)
//   .mount('#app');
// import 'dropzone-vue/dist/dropzone-vue.common.css';

// optionally import default styles
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BCard,
  BButton,
  BMediaAside,
  BImg,
  BMedia,
  BLink,
  BSpinner,
  BBadge,
  BFormFile,
  BFormTextarea,
  BProgress,
  BProgressBar,
  BFormCheckbox,
  BSidebar,
  BForm,
  BInputGroupPrepend,
  BInputGroup,
  BTable,
  VBTooltip,
  BAvatar,
} from "bootstrap-vue";
import { resolve } from "path";
import { reject } from "q";
export default {
  props: ["selected", "id", "inqObj"],
  components: {
    LottieAnimation,
    vueDropzone: vue2Dropzone,
    // DropZone,
    codeIcon,
    BSidebar,
    BForm,
    BInputGroupPrepend,
    BInputGroup,
    BTable,
    BFormCheckbox,
    BProgress,
    BProgressBar,
    BFormTextarea,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    flatPickr,
    BCard,
    BButton,
    BMediaAside,
    BImg,
    BMedia,
    BLink,
    BSpinner,
    BBadge,
    BFormFile,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    VBTooltip,
    BAvatar,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      submitted: false,
      commonData: false,
      settings: {
        sameDate: false,
        sameClass: false,
        sameSec: false,
      },
      file: [],
      mydocs: [],
      moreloading: false,
      plus: "",
      logoloading: false,
      netAmount: 0,
      dataloading: false,
      admittedSections: [],
      currentSections: [],
      classes: [],
      genders: ["Male", "Female"],

      categories: [],
      prog: {
        test_fee: false,
        test_passed: false,
        confirmed: false,
      },
      groups: ["A", "B", "O", "AB"],
      religions: ["ISLAM", "CHRISTIANITY", "HINDUISM", "BUDDHISM", "SIKHISM"],
      codeIcon,
      documents: [],
      uploading: false,
      customObj: [
        {
          id: 0,
          sID: 0,
          feeTypeID: 0,
          amount: 0,
          campusID: this.$store.state.userData.cId,
        },
      ],
      allSubjects: [],
      subjectData: [],
      subFields: [
        { label: "subjects", key: "subjects" },
        { label: "status", key: "status" },
        { key: "actions", label: "actions" },
      ],
      myObj: {
        id: 0,
        grNo: null,
        family_code: null,
        name: null,
        father_name: null,
        gender: null,
        picture: null,
        date_of_birth: null,
        date_of_birth_words: null,
        date_of_admission: null,
        blood_group: null,
        nationality: null,
        religion: null,
        place_of_birth: null,
        mother_tongue: null,
        address_current: null,
        address_permanent: null,
        contact_student: null,
        contact_father: null,
        contact_mother: null,
        contact_home: null,
        contact_tutor: null,
        last_institute: null,
        last_class: null,
        last_session: null,
        last_result: null,
        last_note: null,
        class_admitted: 0,
        cls_admitted: null,
        class_current: 0,
        cls_current: null,
        section_admitted: 0,
        section_current: 0,
        roll_number: null,
        house: null,
        rfid_number: null,
        bform_number: null,
        date_of_registraion: null,
        date_of_readmission: null,
        father_cnic: null,
        father_education: null,
        father_occupation: null,
        monthly_income: null,
        office_phone: null,
        office_address: null,
        father_email: null,
        father_employer: null,
        mother_name: null,
        mother_cnic: null,
        mother_income: null,
        mother_education: null,
        mother_occupation: null,
        mother_office_phone: null,
        mother_office_address: null,
        mother_email: null,
        mother_employer: null,
        guardian_name: null,
        guardian_cnic: null,
        guardian_education: null,
        guardian_occupation: null,
        guardian_office_phone: null,
        guardian_office_address: null,
        guardian_email: null,
        guardian_employer: null,
        guardian_relationship: null,
        emergency_name: null,
        emergency_contact: null,
        emergency_relationship: null,
        physical_handicap: null,
        allergies: null,
        remarks: null,
        monthly_fee: 0,
        discount_percentage: 0,
        challan_due_date: null,
        docs: "",
        status: "registered",
        campusID: 1,
        testPayment: false,
        testPassed: false,
        testCompleted: false,
        sessionID: 0,
        currency: "Rs",
      },
      fphoneerror: false,
      mphoneerror: false,
      phoneerror: false,
      emerPhoneerror: false,
      vueTelOptions: { placeholder: "Enter contact number" },
      dropOptions: {
        disabled: false,
        showDialCodeInList: true,
        showFlags: true,
      },
      emerOpt: { placeholder: "Enter emergency contact" },
      classesOptions: [],
      check: 1,
      config: {
        altFormat: "j M, Y",
        altInput: true,
        dateFormat: "Y-m-d",
      },
      fields: [
        { label: "Family Code", key: "family_code" },
        { label: "father name", key: "father_name" },
        { label: "contact", key: "contact_father" },
      ],
      studentData: [],
      searchQuery: "",
      studentLoading: false,
      initData: null,
      feeAmount: null,
      FeeTypes: [],
      feeIds: [],
      addingMsg: this.$store.state.addMsg,
      uRights: {
        hideFee: false,
      },
      admObj: {
        id: 0,
        sID: 0,
        campusID: this.$store.state.userData.cId,
        testDate: null,
        testStatus: "",
        interviewDate: null,
        interviewStatus: "",
        remarks: "",
        documents: "",
      },
      statusOptions: [
        { text: "Cleared", value: "cleared" },
        { text: "Not Cleared", value: "not cleared" },
      ],
      fObj: null,
      reportDomain: this.$store.state.report.replace(/_/g, ""),
      campuses: [],
      showForm: false,
      studentID: 0,
      inqNo: "",
      inqLoading: false,
      token:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkZW1vQG15c2tvb2wuYXBwIiwianRpIjoiZmRjZjdhYzQtNmY1OS00Nzg0LTkyNDItZjFjNjRhOTdjODQ0IiwiZXhwIjoxOTkzNzk2MDgwLCJpc3MiOiJteXNrb29sLmFwcCIsImF1ZCI6Im15c2tvb2wuYXBwIn0._pMXprq3AJApizQQlcrVXS3-dPyGFMi0nT9OfLQKBlo",
      showError: false,
      db: "",
      school: {},
      siblings: [
        // { name: "Anas", meta_class: "VII - A", branch: "CAMPUS-IV" },
        // { name: "Ahmed", meta_class: "VI - B", branch: "CLIFTON CAMPUS" },
      ],
      otpLoading: false,
      otpContact: "",
      otpError: false,
      showOtp: false,
      code: "",
      enteredCode: [],
      verifyToggle: false,
      inqData: {},
    };
  },
  created() {
    if (!this.$route.query.db) {
      this.showError = true;
    } else {
      this.db = this.$route.query.db;
      this.LoadCampuses();
      setTimeout(() => {
        this.otpError = false;
      }, 300);
    }
  },
  computed: {
    ...mapGetters({ campusID: "campusID" }),

    imgUrl() {
      let img = require("@/assets/images/pages/error.svg");
      if (this.$store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        img = require("@/assets/images/pages/error-dark.svg");
        return img;
      }
      return img;
    },
  },
  methods: {
    ...mapActions({ getDepartment: "getDepartment" }),
    ...mapActions({
      get: "get",
      post: "post",
      put: "put",
      delete: "delete",
      postObj: "postObj",
    }),

    numberToWords(number) {
      const words = [
        "Zero",
        "One",
        "Two",
        "Three",
        "Four",
        "Five",
        "Six",
        "Seven",
        "Eight",
        "Nine",
        "Ten",
        "Eleven",
        "Twelve",
        "Thirteen",
        "Fourteen",
        "Fifteen",
        "Sixteen",
        "Seventeen",
        "Eighteen",
        "Nineteen",
      ];
      const tens = [
        "",
        "",
        "Twenty",
        "Thirty",
        "Forty",
        "Fifty",
        "Sixty",
        "Seventy",
        "Eighty",
        "Ninety",
      ];

      if (number < 20) return words[number];
      if (number < 100)
        return (
          tens[Math.floor(number / 10)] +
          (number % 10 === 0 ? "" : " " + words[number % 10])
        );
      if (number < 1000)
        return (
          this.numberToWords(Math.floor(number / 100)) +
          " Hundred" +
          (number % 100 === 0 ? "" : " and " + this.numberToWords(number % 100))
        );

      // For this specific use case, we assume the year is always in the 2000s
      return (
        "Two Thousand" +
        (number % 1000 !== 0 ? " " + this.numberToWords(number % 1000) : "")
      );
    },
    formatDate() {
      if (this.myObj.date_of_birth) {
        const months = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];
        const dayNames = [
          "",
          "First",
          "Second",
          "Third",
          "Fourth",
          "Fifth",
          "Sixth",
          "Seventh",
          "Eighth",
          "Ninth",
          "Tenth",
          "Eleventh",
          "Twelfth",
          "Thirteenth",
          "Fourteenth",
          "Fifteenth",
          "Sixteenth",
          "Seventeenth",
          "Eighteenth",
          "Nineteenth",
          "Twentieth",
          "Twenty First",
          "Twenty Second",
          "Twenty Third",
          "Twenty Fourth",
          "Twenty Fifth",
          "Twenty Sixth",
          "Twenty Seventh",
          "Twenty Eighth",
          "Twenty Ninth",
          "Thirtieth",
          "Thirty First",
        ];

        const [yearStr, monthStr, dayStr] = this.myObj.date_of_birth.split("-");
        const day = parseInt(dayStr, 10);
        const year = parseInt(yearStr, 10);
        const monthIndex = parseInt(monthStr, 10) - 1;

        this.myObj.date_of_birth_words = `${dayNames[day]} ${
          months[monthIndex]
        } ${this.numberToWords(year)}`;
      } else this.myObj.date_of_birth_words = "";
    },

    setMsg() {
      this.$store.commit("setAddMsg", this.addingMsg);
    },

    handleEvent(event) {
      // console.log(event.key);
      if (event.ctrlKey && event.keyCode === 83) {
        event.preventDefault();
        if (this.check == 1) {
          this.check = 0;
          // console.log(this.check);
          this.shortcutSubmit();
        }
      }
    },

    checkInq() {
      var elem = this.$refs["inq"];
      if (this.inqNo == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async getInq() {
      if (this.checkInq() == true) {
        this.inqLoading = true;
        var obj = {
          url:
            this.$store.state.domain +
            "StudentInquiries/GetSelected?id=" +
            this.inqNo +
            "&db=" +
            this.$store.state.userData.db,
          token: this.$store.state.userData.token,
        };
        let res = await this.get(obj);
        // console.log(res);

        if (res == "NotFound" || Array.isArray(res)) {
          this.$bvToast.toast("No record found", {
            title: "Error!",
            variant: "danger",
            solid: true,
          });
        } else {
          this.LoadInit();
          this.myObj.name = res.name;
          this.myObj.father_name = res.father_name;
          this.myObj.contact_father = res.contact;
          this.myObj.father_cnic = res.cnic;
          this.myObj.father_email = res.email;
          this.myObj.last_institute = res.lastSchool;
          this.myObj.address_current = res.residentialArea;
          this.myObj.address_permanent = res.residentialArea;
          this.myObj.campusID = res.campusID;

          if (res.cls) {
            this.myObj.class_admitted = res.cls;
            this.myObj.class_current = res.cls;
          } else {
            this.myObj.class_admitted = 0;
            this.myObj.class_current = 0;
          }
          if (this.reportDomain == "myskoolhpgs") {
            this.LoadData();
          } else this.FillSections();

          this.myObj.docs = res.attachments;
          if (res.attachments !== null && res.attachments !== "") {
            this.documents = res.attachments.split(",");
          } else this.documents = [];

          this.myObj.date_of_registraion = new Date().toJSON();
          console.log(this.myObj);
          this.showForm = true;
        }
        this.inqLoading = false;
      }
    },
    async sendOtp() {
      if (this.otpContact == "" || this.otpError) {
        this.$bvToast.toast("Please enter the contact number", {
          title: "Error!",
          variant: "danger",
          solid: true,
        });
      } else {
        this.otpLoading = true;
        this.code = `${Math.floor(1000 + Math.random() * 9000)}`;
        var resp = await this.postObj({
          url:
            this.$store.state.domain +
            "Chat/SendWhatsApp?db=/r6Q0RYpjkdemxZWlFH9Ig==&cID=2",
          body: {
            message: this.code + " is your verification code for HPGS Connect",
            media: "",
            contacts: this.otpContact,
            whatsapp: true,
            userID: 1,
          },
          message: "OTP sent to your WhatsApp.",
          context: this,
          token: this.token,
        });
        this.otpLoading = false;
        if (resp) {
          this.enteredCode = new Array(4).fill(null);
          // console.log(this.code);
          this.verifyToggle = true;
          this.showOtp = true;
          setTimeout(() => {
            this.$refs["code0"][0].focus();
          }, 300);
        }
      }
    },
    async checkStudent() {
      if (this.otpContact == "" || this.otpError) {
        this.$bvToast.toast("Please enter the contact number", {
          title: "Error!",
          variant: "danger",
          solid: true,
        });
      } else {
        this.otpLoading = true;
        var obj = {
          url:
            this.$store.state.domain +
            "studentinquiries/getbycontact?db=" +
            this.db +
            "&cId=1&contact=" +
            this.otpContact.replace(/\+/g, ""),
          token: this.token,
        };
        let resp = await this.get(obj);
        this.otpLoading = false;
        if (resp == "NotFound" || Array.isArray(resp) || !resp.inq) {
          // this.$bvToast.toast("No record found", {
          //   title: "Error!",
          //   variant: "danger",
          //   solid: true,
          // });
          this.$router.push({ name: "inquiry-hpgs", query: { reg: true } });
        } else {
          this.inqData = resp;
          this.sendOtp();
        }
      }
    },
    moveNext(ind) {
      // console.log(this.enteredCode);
      if (ind != this.enteredCode.length - 1 && this.enteredCode[ind]) {
        let elem = this.$refs[`code${ind + 1}`][0];
        elem.focus();
      }
      if (this.enteredCode.includes(null) || this.enteredCode.includes("")) {
        this.verifyToggle = true;
      } else this.verifyOtp();
    },
    async verifyOtp() {
      this.verifyToggle = true;
      let check = this.enteredCode.join("");
      if (check == this.code) {
        this.otpLoading = true;

        let res = this.inqData.inq;
        this.siblings = this.inqData.sibling;
        this.LoadInit(res.campusID);
        this.myObj.name = res.name;
        this.myObj.father_name = res.father_name;
        this.myObj.contact_father = res.contact;
        this.myObj.father_cnic = res.cnic;
        this.myObj.father_email = res.email;
        this.myObj.last_institute = res.lastSchool;
        this.myObj.address_current = res.residentialArea;
        this.myObj.address_permanent = res.residentialArea;
        this.myObj.campusID = res.campusID;
        if (res.cls) {
          this.myObj.class_admitted = res.cls;
          this.myObj.class_current = res.cls;
        } else {
          this.myObj.class_admitted = 0;
          this.myObj.class_current = 0;
        }
        this.LoadData();
        this.myObj.docs = res.attachments;
        if (res.attachments !== null && res.attachments !== "") {
          this.documents = res.attachments.split(",");
        } else this.documents = [];

        this.myObj.date_of_registraion = new Date().toJSON();
        // console.log(this.myObj);
        let obj = this.campuses.find((el) => el.id == this.myObj.campusID);
        if (obj) {
          this.school.address = obj.address;
          this.school.phone = obj.phone;
          this.school.mobile = obj.mobile;
        }
        this.showForm = true;
        this.showOtp = false;
        this.code = "";
        this.enteredCode = [];
        this.verifyToggle = false;

        this.otpLoading = false;
      } else {
        this.$bvToast.toast("OTP entered is incorrect.", {
          title: "Login Falied!",
          variant: "danger",
          solid: true,
        });
        this.verifyToggle = false;
      }
    },
    setData() {
      this.myObj.class_admitted = 0;
      this.myObj.section_admitted = 0;
      this.admittedSections = [];
      this.LoadData();
      let obj = this.campuses.find((el) => el.id == this.myObj.campusID);
      if (obj) {
        this.school.address = obj.address;
        this.school.phone = obj.phone;
        this.school.mobile = obj.mobile;
      }
    },
    async LoadCampuses() {
      var obj = {
        url: this.$store.state.domain + "campuses?db=" + this.db + "&cId=1",
        token: this.token,
      };
      this.campuses = await this.get(obj);
      //   console.log(this.campuses);
      if (this.campuses.length > 0) {
        this.school = { ...this.campuses[0] };
        // this.myObj.campusID = this.campuses[0].id;
        // this.LoadData();
      } else this.showError = true;
    },
    async loadSubjects() {
      var obj2 = {
        url:
          this.$store.state.domain +
          "Subjects?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.allSubjects = await this.get(obj2);
      // console.log(this.allSubjects);
      if (this.studentID == 0) this.setSubs();
    },
    setSubs() {
      this.subjectData = this.allSubjects.map((el) => {
        return {
          id: 0,
          sID: this.studentID,
          subID: el.id,
          subName: el.subject,
          isExcluded: false,
          campusID: this.$store.state.userData.cId,
        };
      });
      // console.log(this.subjectData);
    },

    async loadFeeType() {
      this.FeeTypes - [];
      var obj = {
        url:
          this.$store.state.domain +
          "FeeType/LoadAll?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.FeeTypes = await this.get(obj);
      this.FeeTypes = this.FeeTypes.filter((el) => el.recurrence != "default");
    },
    changeOptions(ind) {
      this.feeIds = this.customObj.reduce((acc, el) => {
        acc.push(el.feeTypeID);
        return acc;
      }, []);
      // console.log(this.feeIds);
    },
    AddValue() {
      this.customObj.push({
        id: 0,
        sID: this.studentID,
        feeTypeID: 0,
        amount: 0,
        campusID: this.$store.state.userData.cId,
      });
      // console.log(this.customObj);
    },
    async removeValue(item, ind) {
      if (item.id == 0) {
        this.customObj.splice(ind, 1);
        this.feeIds = this.feeIds.filter((el) => el != item.feeTypeID);
        // console.log(this.feeIds,this.customObj);
      } else {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "StudentsCustomFee/" +
            item.id +
            "?db=" +
            this.$store.state.userData.db,
          body: null,
          grading: true,
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) {
          this.customObj.splice(ind, 1);
          this.feeIds = this.feeIds.filter((el) => el != item.feeTypeID);
        }
        // console.log(this.customObj);
      }
    },
    CheckValues() {
      let state = true;
      this.customObj = this.customObj.filter((el) => el.feeTypeID != 0);
      this.customObj.forEach((el) => {
        el.amount = parseInt(el.amount);
        if (el.feeTypeID !== 0 && (isNaN(el.amount) || el.amount < 0)) {
          state = false;
        }
      });
      return state;
    },

    uploadDocument(e) {
      // console.log(e.target.files);
      // this.documents = Array.from(e.target.files);
      // console.log(this.documents);
      let docs = this.$refs.studentdocs.files;
      if (docs.length != 0) {
        this.uploading = true;
        let formData = new FormData();
        docs.forEach((el) => {
          formData.append("file", el);
        });
        var axios = require("axios");
        axios
          .post("https://upload.appick.io", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((e) => {
            // console.log(e.data);
            let resp = e.data.myresp;
            resp.forEach((el) => {
              this.documents.push(el.path);
            });
            this.myObj.docs = this.documents.join(",");
            this.uploading = false;
            // console.log(this.myObj.docs);
          });
      }
    },
    deleteDocument(index) {
      // console.log(index);
      this.documents.splice(index, 1);
      this.myObj.docs = this.documents.join(",");
      // console.log(this.myObj.docs);
    },
    CheckRequired(name) {
      var elem = this.$refs[name];
      if (this.myObj.name == "" || this.myObj.name > 20) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }

      // return (elem.state = this.$v.depObj.name.required);
    },
    saveForm() {
      // console.log(e);
      this.$bvToast.toast("This GR number is already registerd!", {
        title: "Error!",
        variant: "danger",
        solid: true,
      });
    },
    validationFamilyInfo() {
      return new Promise((resolve, reject) => {
        resolve(true);
        // this.CheckCnic();
        // this.CheckMCnic();
        // this.CheckEmail();
        // if (
        //   this.CheckCnic() == false ||
        //   this.CheckMCnic() == false ||
        //   this.CheckEmail() == false
        // ) {
        //   reject(false);
        // } else {
        //   resolve(true);
        // }
      });
    },
    validationEmergency() {
      return new Promise((resolve, reject) => {
        resolve(true);
        // this.CheckEmerName();
        // this.CheckEmerRelation();
        // this.CheckEmerContact();
        // if (
        //   this.CheckEmerName() == false ||
        //   this.CheckEmerRelation() == false ||
        //   this.CheckEmerContact() == false
        // ) {
        //   reject(false);
        //   console.log("rej");
        // } else {
        //   resolve(true);
        // }
      });
    },
    async shortcutSubmit(val) {
      // console.log(val);
      // console.log("obj", this.myObj);
      this.check = 0; // due to save button click
      this.CheckName();
      this.CheckFName();
      this.CheckDob();
      this.CheckClassAdmit();
      this.CheckGender();
      if (!this.myObj.contact_father) this.fphoneerror = true;
      // this.CheckAdmitSec();
      // this.CheckClassCurrent();
      // this.CheckCurrentSec();
      // this.CheckGrNo();
      // this.CheckFees();
      if (this.CheckName() == false) {
        this.$bvToast.toast("Please enter the name", {
          title: "Error!",
          variant: "danger",
          solid: true,
        });
        this.check = 1;
      } else if (this.CheckFName() == false) {
        this.$bvToast.toast("Please enter the father name", {
          title: "Error!",
          variant: "danger",
          solid: true,
        });
        this.check = 1;
      }
      // else if (this.CheckGrNo() == false) {
      //   this.$bvToast.toast("Please enter the registration number", {
      //     title: "Error!",
      //     variant: "danger",
      //     solid: true,
      //   });
      //   this.check = 1;
      // }
      else if (this.CheckClassAdmit() == false) {
        this.$bvToast.toast("Please enter class", {
          title: "Error!",
          variant: "danger",
          solid: true,
        });
        this.check = 1;
      } else if (this.CheckGender() == false) {
        this.$bvToast.toast("Please enter gender", {
          title: "Error!",
          variant: "danger",
          solid: true,
        });
        this.check = 1;
      } else if (this.CheckDob() == false) {
        this.$bvToast.toast("Please enter date of birth", {
          title: "Error!",
          variant: "danger",
          solid: true,
        });
        this.check = 1;
      }
      // else if (this.CheckAdmitSec() == false) {
      //   this.$bvToast.toast("Please enter section", {
      //     title: "Error!",
      //     variant: "danger",
      //     solid: true,
      //   });
      //   this.check = 1;
      // }
      //  else if (this.CheckClassCurrent() == false) {
      //   this.$bvToast.toast("Please enter class (current)", {
      //     title: "Error!",
      //     variant: "danger",
      //     solid: true,
      //   });
      //   this.check = 1;
      // } else if (this.CheckCurrentSec() == false) {
      //   this.$bvToast.toast("Please enter section (current)", {
      //     title: "Error!",
      //     variant: "danger",
      //     solid: true,
      //   });
      //   this.check = 1;
      // } else if (this.CheckFees() == false) {
      //   this.$bvToast.toast("Please enter the monthly fees", {
      //     title: "Error!",
      //     variant: "danger",
      //     solid: true,
      //   });
      //   this.check = 1;
      // } else if (this.CheckValues() == false) {
      //   this.$bvToast.toast("Invalid custom fee entered", {
      //     title: "Error!",
      //     variant: "danger",
      //     solid: true,
      //   });
      //   this.check = 1;
      // }
      else if (!this.myObj.contact_father) {
        this.$bvToast.toast("Please enter father's contact", {
          title: "Error!",
          variant: "danger",
          solid: true,
        });
        this.check = 1;
      } else if (this.fphoneerror || this.mphoneerror || this.phoneerror) {
        this.$bvToast.toast("Invalid contact details entered", {
          title: "Error!",
          variant: "danger",
          solid: true,
        });
        this.check = 1;
      } else if (this.emerPhoneerror) {
        this.$bvToast.toast("Invalid emergency contact entered", {
          title: "Error!",
          variant: "danger",
          solid: true,
        });
        this.check = 1;
      } else {
        this.$bvModal.show("modal-login");

        this.myObj.status = "registered";
        this.myObj.class_current = this.myObj.class_admitted;
        this.myObj.section_current = this.myObj.section_admitted;
        this.myObj.address_permanent = this.myObj.address_current;
        this.myObj.campus_Admitted = this.myObj.campusID;

        if (this.myObj.date_of_birth == "") {
          this.myObj.date_of_birth = null;
        }

        this.myObj.grNo = this.myObj.grNo ? this.myObj.grNo.toString() : "";
        this.myObj.family_code = this.myObj.family_code
          ? parseInt(this.myObj.family_code)
          : 0;
        this.myObj.monthly_income = this.myObj.monthly_income
          ? parseInt(this.myObj.monthly_income)
          : 0;
        this.myObj.mother_income = this.myObj.mother_income
          ? parseInt(this.myObj.mother_income)
          : 0;

        let data = {
          student: this.myObj,
          customFee: [],
          subjectsEnrollnment: [],
          admissionTestData: {
            id: 0,
            sID: 0,
            campusID: this.myObj.campusID,
            testDate: null,
            testStatus: "",
            interviewDate: null,
            interviewStatus: "",
            remarks: "",
            documents: "",
          },
        };

        var status = await this.post({
          url:
            this.$store.state.domain +
            // "students/SaveStudent?db=" +
            "students/SaveStudentWithData?db=" +
            this.db +
            "&sms=false&inqID=" +
            this.inqData.inq.id,
          body: data,
          message: "Student saved successfully.",
          context: this,
          token: this.token,
          savingStudent: true,
        });
        if (status) {
          const class_admitted = this.myObj.class_admitted;
          const cls_admitted = this.myObj.cls_admitted;
          const class_current = this.myObj.class_current;
          const cls_current = this.myObj.cls_current;
          const section_admitted = this.myObj.section_admitted;
          const section_current = this.myObj.section_current;

          this.myObj = {
            id: 0,
            grNo: null,
            family_code: null,
            name: null,
            father_name: null,
            gender: null,
            picture: null,
            date_of_birth: null,
            date_of_birth_words: null,
            date_of_admission: null,
            blood_group: null,
            nationality: null,
            religion: null,
            place_of_birth: null,
            mother_tongue: null,
            address_current: null,
            address_permanent: null,
            contact_student: null,
            contact_father: null,
            contact_mother: null,
            contact_home: null,
            contact_tutor: null,
            last_institute: null,
            last_class: null,
            last_session: null,
            last_result: null,
            last_note: null,
            class_admitted: 0,
            cls_admitted: null,
            class_current: 0,
            cls_current: null,
            section_admitted: 0,
            section_current: 0,
            roll_number: null,
            house: null,
            surname: null,
            rfid_number: null,
            bform_number: null,
            date_of_registraion: null,
            date_of_readmission: null,
            father_cnic: null,
            father_education: null,
            father_occupation: null,
            monthly_income: null,
            office_phone: null,
            office_address: null,
            father_email: null,
            father_employer: null,
            mother_name: null,
            mother_cnic: null,
            mother_income: null,
            mother_education: null,
            mother_occupation: null,
            mother_office_phone: null,
            mother_office_address: null,
            mother_email: null,
            mother_employer: null,
            guardian_name: null,
            guardian_cnic: null,
            guardian_education: null,
            guardian_occupation: null,
            guardian_office_phone: null,
            guardian_office_address: null,
            guardian_email: null,
            guardian_employer: null,
            guardian_relationship: null,
            emergency_name: null,
            emergency_contact: null,
            emergency_relationship: null,
            physical_handicap: null,
            allergies: null,
            remarks: null,
            monthly_fee: 0,
            discount_percentage: 0,
            challan_due_date: null,
            docs: "",
            status: "registered",
            campusID: this.$store.state.userData.cId,
            testPayment: false,
            testPassed: false,
            testCompleted: false,
            sessionID: 0,
            currency: "Rs",
          };
          this.documents = [];
          // this.cleanStates();
          var elem = this.$refs["name"];
          elem.state = undefined;
          var elem = this.$refs["fname"];
          elem.state = undefined;
          var elem = this.$refs["class_admitted"];
          elem.state = undefined;
          var elem = this.$refs["dob"];
          elem.state = undefined;
          var elem = this.$refs["gender"];
          elem.state = undefined;
          // var elem = this.$refs["section_admitted"];
          // elem.state = undefined;
          this.fphoneerror = false;
          this.mphoneerror = false;
          this.phoneerror = false;
          this.emerPhoneerror = false;
          this.admittedSections = [];

          this.$bvModal.hide("modal-login");
          this.check = 1;

          this.submitted = true;
        } else {
          this.$bvModal.hide("modal-login");
          this.check = 1;
        }
      }
    },
    selectMoreFile() {
      //this.moreFiles = this.$refs.mfile.files;
      if (this.$refs.mfile.files.length !== 0) {
        this.moreloading = true;
        var formdata = new FormData();
        for (var i = 0; i < this.$refs.mfile.files.length; i++) {
          formdata.append("file", this.$refs.mfile.files[i]);
        }

        var requestOptions = {
          method: "POST",
          body: formdata,
          redirect: "follow",
        };

        fetch("https://upload.appick.io", requestOptions)
          .then((response) => response.json()) //2
          .then((result) => {
            console.log();
            //this.moreImages.push(result.myresp);

            for (var i = 0, len = result.myresp.length; i < len; ++i) {
              this.moreloading = false;
              var fn = result.myresp[i].path;
              this.mydocs.push({
                path: fn,
                orginal: result.myresp[i].originalname,
              });
            }
            console.log(this.mydocs);
          })

          .catch((error) => console.log("error", error));
      }
    },
    deleteLogo() {
      //console.log(0);
      this.myObj.picture = null;
      this.logoloading = false;
      this.$refs.fileProfile.value = null;
    },
    selectlogo() {
      // console.log("Osama");
      var axios = require("axios");

      this.fileProfile = this.$refs.fileProfile.files[0];
      //console.log(this.fileProfile.name);

      //console.log(this.$refs.fileProfile.files[0], "!!!!!!!!!!" );
      //Upload cover
      if (this.$refs.fileProfile.files[0] !== "") {
        this.logoloading = true;

        let formData = new FormData();
        formData.append("file", this.$refs.fileProfile.files[0]);
        //console.log(this.fileProfile);
        axios
          .post("https://upload.appick.io/profile-picture", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((e) => {
            // console.log(e.data);
            // this.file = undefined;
            // var fn1 = e.data.myresp[0].path;
            var fn1 = e.data.file.path;
            // console.log(fn1);
            this.plus = fn1;
            this.myObj.picture = this.plus;
            // console.log(this.plus);
            // console.log(this.myObj.picture);
            this.logoloading = "loaded";

            //  console.log(this.loading);
          })
          .catch((err) => {
            this.logoloading = false;
            console.log(err);
          });
      }
    },

    cleanStates() {
      this.showForm = false;
      this.submitted = false;
      this.inqNo = "";
      this.otpContact = "";
      setTimeout(() => {
        this.otpError = false;
      }, 300);

      // this.currentSections = [];
    },
    CheckDiscount() {
      var elem = this.$refs["discount_percentage"];
      if (this.myObj.discount_percentage == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckAdmitSec() {
      // console.log(this.myObj.section_admitted);
      var elem = this.$refs["section_admitted"];
      if (this.myObj.section_admitted == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckCurrentSec() {
      // console.log(this.myObj.section_current);
      var elem = this.$refs["section_current"];
      if (this.myObj.section_current == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckClassAdmit() {
      var elem = this.$refs["class_admitted"];
      if (this.myObj.class_admitted == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckClassCurrent() {
      var elem = this.$refs["class_current"];
      if (this.myObj.class_current == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckName() {
      var elem = this.$refs["name"];
      if (this.myObj.name == "" || this.myObj.name < 3) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckFName() {
      var elem = this.$refs["fname"];
      if (this.myObj.father_name == "" || this.myObj.father_name < 3) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckGender() {
      var elem = this.$refs["gender"];
      if (!this.myObj.gender) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckDob() {
      var elem = this.$refs["dob"];
      if (this.myObj.date_of_birth == "" || this.myObj.date_of_birth == null) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckOTPContact(s, e) {
      if (e.valid) {
        this.otpError = false;
        this.otpContact = e.number;
      } else {
        this.otpError = true;
      }
    },
    CheckFContact(s, e) {
      // console.log(this.myObj.contact_father);
      if (this.myObj.contact_father != "") {
        if (e.valid) {
          this.fphoneerror = false;
          this.myObj.contact_father = e.number;
          // console.log(this.myObj.contact_father);
        } else {
          this.fphoneerror = true;
        }
      } else {
        this.fphoneerror = false;
      }
      // console.log(this.myObj.contact_father.length);
      // if (this.myObj.contact_father.length == 12) {
      //   this.fphoneerror = false;
      //   return true;
      // } else {
      //   this.fphoneerror = true;
      //   return false;
      // }
    },
    CheckMContact(s, e) {
      // console.log(this.myObj.contact_mother);
      if (this.myObj.contact_mother != "") {
        if (e.valid) {
          this.mphoneerror = false;
          this.myObj.contact_mother = e.number;
          // console.log(this.myObj.contact_mother);
        } else {
          this.mphoneerror = true;
        }
      } else {
        this.mphoneerror = false;
      }
      // console.log(this.myObj.contact_mother.length);
      // if (this.myObj.contact_mother.length == 12) {
      //   this.mphoneerror = false;
      //   return true;
      // } else {
      //   this.mphoneerror = true;
      //   return false;
      // }
    },
    CheckContact(s, e) {
      // console.log(this.myObj.contact_student);
      if (this.myObj.contact_student != "") {
        if (e.valid) {
          this.phoneerror = false;
          this.myObj.contact_student = e.number;
          // console.log(this.myObj.contact_student);
        } else {
          this.phoneerror = true;
        }
      } else {
        this.phoneerror = false;
      }
      // console.log(this.myObj.contact_student.length);
      // if (this.myObj.contact_student.length == 12) {
      //   this.phoneerror = false;
      //   return true;
      // } else {
      //   this.phoneerror = true;
      //   return false;
      // }
    },
    CheckCnic() {
      var elem = this.$refs["fCnic"];
      if (this.myObj.father_cnic == null || this.myObj.father_cnic == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckMCnic() {
      var elem = this.$refs["mCnic"];
      if (this.myObj.mother_cnic == null || this.myObj.mother_cnic == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckEmail() {
      var elem = this.$refs["fEmail"];
      if (this.myObj.father_email == null || this.myObj.father_email == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckGCnic() {
      var elem = this.$refs["gCnic"];
      if (this.myObj.guardian_cnic == null || this.myObj.guardian_cnic == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckGEmail() {
      var elem = this.$refs["gEmail"];
      if (
        this.myObj.guardian_email == null ||
        this.myObj.guardian_email == ""
      ) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckEmerName() {
      var elem = this.$refs["emerName"];
      if (
        this.myObj.emergency_name == null ||
        this.myObj.emergency_name == ""
      ) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckEmerRelation() {
      var elem = this.$refs["emerRel"];
      if (
        this.myObj.emergency_relationship == null ||
        this.myObj.emergency_relationship == ""
      ) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckEmerContact(s, e) {
      // console.log(e);
      if (this.myObj.emergency_contact != "") {
        // console.log(e);
        if (e.valid) {
          this.emerPhoneerror = false;
          this.myObj.emergency_contact = e.number;
          // console.log(this.myObj.emergency_contact);
        } else {
          this.emerPhoneerror = true;
        }
      } else {
        this.emerPhoneerror = false;
      }
      // if (this.myObj.emergency_contact.length == 12) {
      //   // console.log(this.myObj.emergency_contact.length);
      //   this.emerPhoneerror = false;
      //   return true;
      // } else {
      //   this.emerPhoneerror = true;
      //   return false;
      // }
    },
    CheckFees() {
      // console.log(this.myObj.monthly_fee);
      const regex = /^(\d*([.,](?=\d{3}))?\d+)+((?!\2)[.,]\d\d)?$/;
      var elem = this.$refs["monthly_fee"];
      if (regex.test(this.myObj.monthly_fee)) {
        if (this.myObj.monthly_fee < 0) {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else {
        return (elem.state = false);
      }
    },
    CheckGrNo() {
      var elem = this.$refs["grNo"];
      if (this.myObj.grNo == null || this.myObj.grNo == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CalculateDiscount() {
      // var re = /^((100)|(\d{1,2}(.\d*)?))%$/
      if (
        this.myObj.discount_percentage < 0 ||
        this.myObj.discount_percentage > 100
      ) {
        this.myObj.discount_percentage = 0;
      } else {
        var percent = this.myObj.discount_percentage / 100;
        var discount = percent * this.myObj.monthly_fee;
        this.netAmount = this.myObj.monthly_fee - discount;
      }

      // console.log(netAmount);
    },
    FillSections(text) {
      //return console.log(this.classes);
      // console.log(this.myObj.class_admitted);

      this.classes.forEach((elem) => {
        if (elem.cls.id === this.myObj.class_admitted) {
          if (elem.sections.length != 0) {
            this.admittedSections = elem.sections;
          } else {
            // console.log("null");
            this.admittedSections = [];
          }
        }
      });
      if (text !== "filled") this.myObj.section_admitted = 0;
      // console.log("Thisis", this.admittedSections);
    },
    async FillSectionsCurrent(text) {
      //return console.log(this.classes);
      // console.log(this.myObj.class_current);

      this.classes.forEach((elem) => {
        if (elem.cls.id === this.myObj.class_current) {
          if (elem.sections.length != 0) {
            this.currentSections = elem.sections;
          } else {
            // console.log("null");
            this.currentSections = [];
          }
        }
      });
      if (text !== "filled") this.myObj.section_current = 0;
      // console.log("Thisis", this.currentSections);

      // api for monthly fee acc to class
      if (
        text == "select" &&
        this.selected === "add" &&
        (this.myObj.monthly_fee == 0 || this.myObj.monthly_fee == "")
      ) {
        var obj = {
          url:
            this.$store.state.domain +
            "Students/LoadClassFee?clsID=" +
            this.myObj.class_current +
            "&campusId=" +
            this.$store.state.userData.cId +
            "&db=" +
            this.$store.state.userData.db,
          token: this.$store.state.userData.token,
        };
        this.myObj.monthly_fee = await this.get(obj);
        // console.log("fee:", this.myObj.monthly_fee);
      }
    },
    async LoadStudent() {
      var obj = {
        url:
          this.$store.state.domain +
          "Students/LoadStudentData?db=" +
          this.$store.state.userData.db +
          "&sID=" +
          this.studentID,
        token: this.$store.state.userData.token,
      };

      let result = await this.get(obj);
      this.myObj = result.student;
      if (this.myObj.currency == null) this.myObj.currency = "Rs";
      // console.log("obj:", this.myObj);
      this.customObj = result.customFee;

      if (!this.customObj || this.customObj.length == 0) {
        this.customObj = [
          {
            id: 0,
            sID: this.studentID,
            feeTypeID: 0,
            amount: 0,
            campusID: this.$store.state.userData.cId,
          },
        ];
      } else this.changeOptions();
      // console.log(this.customObj);

      if (!result.admissionTestData) {
        this.admObj = {
          id: 0,
          sID: this.studentID,
          campusID: this.$store.state.userData.cId,
          testDate: null,
          testStatus: "",
          interviewDate: null,
          interviewStatus: "",
          remarks: "",
          documents: "",
        };
      } else {
        this.admObj = result.admissionTestData;
      }

      this.subjectData = result.subjectsEnrollnment;
      await this.loadSubjects();
      if (!this.subjectData || this.subjectData.length == 0) {
        this.setSubs();
      } else {
        let ids = this.subjectData.map((el) => el.subID);
        let data = this.allSubjects.reduce((acc, el) => {
          if (!ids.includes(el.id)) {
            acc.push({
              id: 0,
              sID: this.studentID,
              subID: el.id,
              subName: el.subject,
              isExcluded: false,
              campusID: this.$store.state.userData.cId,
            });
          }
          return acc;
        }, []);
        this.subjectData = [...this.subjectData, ...data];
        // console.log(data, this.subjectData);
      }

      if (this.myObj.picture !== null && this.myObj.picture !== "") {
        this.plus = this.myObj.picture;
        this.logoloading = "loaded";
      }
      if (this.myObj.docs !== null && this.myObj.docs !== "") {
        this.documents = this.myObj.docs.split(",");
        // console.log(this.documents);
      }
      if (this.myObj.status.toLowerCase() !== "left") {
        this.$store.commit("stdStatus", this.myObj.status.toLowerCase());
      }
      this.LoadData();
    },
    LoadData() {
      this.dataloading = true;

      var axios = require("axios");
      var config = {
        method: "get",
        url:
          this.$store.state.domain +
          "myclasses/LoadClasses?db=" +
          this.db +
          "&cID=" +
          this.myObj.campusID +
          "&dID=0",
        headers: {
          Authorization: "bearer " + this.token,
        },
      };

      axios(config)
        .then((response) => {
          // console.log(response.data.data);
          this.classes = [];
          this.classes = response.data.data;
          this.classesOptions = [];
          this.classes.forEach((el) => this.classesOptions.push(el.cls));
          // this.FillSections("filled");
          // this.FillSectionsCurrent("filled");
          this.dataloading = false;
        })
        .catch(function (error) {
          console.log(error);
          this.dataloading = false;
        });
    },
    async LoadInit(id) {
      var obj = {
        url:
          this.$store.state.domain +
          "Students/AddInit?campusId=" +
          id +
          "&db=" +
          this.db,
        token: this.token,
      };
      let initData = await this.get(obj);
      // console.log("data:", initData);
      this.myObj.grNo = initData.newG;
      this.myObj.family_code = initData.fCode;
    },
    async LoadSettings() {
      let res = await this.post({
        url:
          this.$store.state.domain +
          "Settings/getSettings?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        body: ["family_update"],
        context: this,
        token: this.$store.state.userData.token,
        subjects: true,
      });
      this.fObj = res.find((el) => el.key == "family_update");
      // console.log(this.fObj);
    },
    async formSubmitted() {
      this.CheckFContact();
      this.CheckMContact();
      this.CheckContact();
      if (
        this.CheckFContact() == false ||
        this.CheckMContact() == false ||
        this.CheckContact() == false
      ) {
        console.log("invalid");
        return this.$bvToast.toast("Please enter all the required fields.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-right",
        });
      } else {
        console.log("valid");
        this.myObj.monthly_fee = parseInt(this.myObj.monthly_fee);
        this.myObj.roll_number = parseInt(this.myObj.roll_number);
        // if (this.documents.length != 0) {
        //   let formData = new FormData();
        //   this.documents.forEach((el) => {
        //     formData.append("file", el);
        //   });
        //   var axios = require("axios");
        //   axios
        //     .post("https://upload.appick.io", formData, {
        //       headers: {
        //         "Content-Type": "multipart/form-data",
        //       },
        //     })
        //     .then((e) => {
        //       console.log(e.data);
        //       let resp = e.data.myresp
        //       resp.forEach(doc => {
        //         this.myObj.docs += doc.path + ","
        //       })
        //     });
        // }
        if (this.myObj.id == 0) {
          //Add
          console.log("!!!!!!!!!!!!!", this.myObj);
          this.request = true;
          var status = await this.post({
            url:
              this.$store.state.domain +
              "students?db=" +
              this.$store.state.userData.db,
            // +
            // "&cId=" +
            // this.$store.state.userData.cId,
            body: this.myObj,
            message: "Student added successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          if (status == true) {
            this.$router.push("/manage-student");
          }
        } else {
          //Edit
          // this.request = true;
          var status = await this.put({
            url:
              this.$store.state.domain +
              "students/" +
              this.myObj.id +
              "?db=" +
              this.$store.state.userData.db,
            body: this.myObj,
            message: "Student updated successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          // this.request = false;
          if (status) {
            this.$router.push("/manage-student");
          }
        }
      }
    },
  },
};
</script>
<style>
.wizard-tab-content {
  padding-top: 10px !important;
}
.heading {
  padding: 0;
  margin: 2.171303074670571vw 0;
  /* text-shadow: -4px 0 5px white, 0 4px 5px white, 4px 0 5px white,
      0 -4px 5px white; */
}
.input-form {
  margin: 0 70px;
  background: #fff;
}
#fileInput .custom-file-label {
  color: #707070;
  border: 1px dashed #33312f;
  text-align: center;
  padding: 2.5rem 1.5rem;
  width: 100%;
}
#fileInput input {
  display: none;
}
#imgInput input {
  display: none;
}
#imgInput {
  /* height: 8rem; */
  width: 50%;
}
#imgInput div {
  height: 8vw;
}
#imgInput .custom-file-label {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.type {
  background-color: red;
  border-radius: 10px;
  font-size: 0.8rem;
  padding: 10px;
  color: white;
}
.filename {
  padding-left: 25px;
}
.delete {
  margin: 0 0 0 auto;
  color: red;
}
#checkbox-1 {
  margin-right: 10px !important;
}
.submit {
  background-color: #00c192;
  color: #fff;
  width: 16.5vw;
  height: 4vw;
  font-weight: bold;
  border-color: #00c192;
}
.form-file-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 20px;
}
/* #prop-det, #prop-det div {
    height: 100%;
  } */

.item-group {
  width: 50%;
  /* margin-top: 2.269399707174231vw; */
}
.inputfields,
.inputfields:focus {
  border: 1px solid #33312f;
  border-radius: 0.5856515373352855vw;
  height: 2.562225475841874vw;
  outline: none !important;
  resize: none;
}
@media only screen and (max-width: 990px) {
  .mob-setting {
    padding: 0 1rem !important;
    width: 100vw !important;
    margin: 0 auto !important;
  }
  .mobile-view {
    margin: 0 !important;
  }
  /* .col-form-label,
    .form-file-text,
    .custom-control-label {
      font-size: 3vw;
    } */
  .item-group {
    margin-top: 0;
  }
  .margin-top-mobile {
    margin-top: 5vw;
  }
  /* .col-form-label {
      display: none;
    } */
  .form-input-mobile-text {
    width: 92vw;
    margin: 10px auto;
    border-radius: 2.8301610541727673vw;
    height: 12.781844802342606vw;
    font-size: 5.171303074670571vw;
    background-color: #f2f2f2 !important;
    border: 1px solid #33312f;
  }
  .form-input-mobile-text:focus {
    background-color: #00c192 !important;
    color: #fff !important;
  }
  .form-input-mobile-text::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgb(190, 190, 190);
    opacity: 1; /* Firefox */
    font-family: DINNextRoundedLTW01-Regular;
  }

  .form-input-mobile-text:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: rgb(190, 190, 190);
  }

  .form-input-mobile-text::-ms-input-placeholder {
    /* Microsoft Edge */
    color: rgb(190, 190, 190);
  }

  .form-input-mobile-text:focus::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #fff;
  }
  .form-file-input-mobile {
    border-radius: 2.8301610541727673vw;
    height: 12.781844802342606vw;
    background-color: #f2f2f2 !important;
    color: #fff !important;
  }
  .custom-file-label {
    border-radius: 2.8301610541727673vw !important;
    height: 12.781844802342606vw;
    background-color: #f2f2f2 !important;
    color: #000 !important;
    height: 40vw;
    border: 1px solid #33312f;
  }
  .submit {
    width: 50vw;
    height: 10vw;
  }
  .filename {
    font-size: 3vw;
  }
  .shadowHead {
    font-size: 8vw !important;
  }
  #imgInput div {
    height: 29vw !important;
  }
  .item-group {
    height: 30vw !important;
  }
}

@media only screen and (min-width: 1366px) {
  .submit {
    width: 15.5vw;
    height: 7.8vh;
  }
}
@media only screen and (min-width: 1440px) {
  .submit {
    width: 14.7vw;
    height: 6.7vh;
  }
}
@media only screen and (min-width: 1536px) {
  .submit {
    width: 13.8vw;
    height: 6.9vh;
  }
}
@media only screen and (min-width: 1920px) {
  .submit {
    width: 11vw;
    height: 5.5vh;
  }
}
#overlay {
  position: fixed; /* Sit on top of the page content */
  display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}
</style>
<style scoped>
.rcorners2 {
  border-radius: 100px;
  border: 4px solid var(--primary);
  padding: 2px;
}
.sticky {
  position: fixed;
  /* top: 0; */
  right: 2rem;
  /* margin-bottom: 30px; */
  /* position: fixed;
      bottom: 5%;
      z-index: 1031;  */
}
.file-uploader {
  position: relative;
  border: 1px solid #000;
  width: 100%;
  line-height: 30px;
  text-align: center;
  border-radius: 10px;
  font-family: "Helvetica", sans-serif;
  height: 100px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.file_upload {
  opacity: 0;

  /* IE 8 */
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";

  /* IE 5-7 */
  filter: alpha(opacity=0);

  /* Netscape or and older firefox browsers */
  -moz-opacity: 0;

  /* older Safari browsers */
  -khtml-opacity: 0;

  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.file {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.file-type {
  background-color: #e62e2d;
  font-family: "Helvetica", sans-serif;
  color: white;
  border-radius: 10px;
  margin-right: 15px;
  height: 50px;
  width: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.file-name {
  font-family: "Helvetica", sans-serif;
  margin: 0px;
}

.file-delete i {
  color: #e62e2d;
  font-size: 2rem;
  cursor: pointer;
}
.modal-header {
  display: none;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
